import React from 'react'
// import { css } from 'emotion'
import { css } from '@emotion/core'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// Components
import Hero from '../components/hero'

// Styles
import colors from '../styles/colors'

// Content
import questions from '../content/questions'

// Icons
import { PlusIcon } from 'mdi-react'

class FAQPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedIndex: null,
    }
  }

  isExpanded(index) {
    return index === this.state.expandedIndex ? true : false
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Ekko App - FAQ</title>
        </Helmet>
        <Hero
          title="FAQ"
          subTitle="Har du spørgsmål? Vi kender det godt og derfor har vi samlet nogle af de spørgsmål vi hører oftest. Måske findes dit svar her og ellers er du altid velkommen til at kontakte os"
        />
        <div css={container} className={'container full-width'}>
          <div className="inner-container">
            <div className="questions-container">
              {questions.map((question, questionsIndex) => (
                <div
                  className={
                    ' question-container ' +
                    (this.isExpanded(questionsIndex) ? 'expanded' : '')
                  }
                  key={questionsIndex}
                >
                  <button
                    className="title"
                    onClick={() => {
                      if (this.state.expandedIndex === questionsIndex) {
                        this.setState({ expandedIndex: null })
                      } else {
                        this.setState({ expandedIndex: questionsIndex })
                      }
                    }}
                  >
                    <PlusIcon />
                    <p className="lead">{question.question}</p>
                  </button>
                  <div className="answer">
                    {question.answer.map((paragraph, paragraphKey) => (
                      <p key={paragraphKey}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

function container() {
  return css`
    padding: 150px 0;
    background-color: ${colors.ultraLightGrey};

    .questions-container {
      background-color: ${colors.white};
      border: 1px ${colors.midGrey} solid;

      .question-container {
        border-bottom: 1px ${colors.midGrey} solid;

        .title {
          display: flex;
          padding: 1rem;
          transition: background-color 80ms ease;
          background-color: transparent;
          border: 0;
          width: 100%;
          border-left: 3px transparent solid;
          text-align: left;

          &:focus {
            border-left: 3px ${colors.deepSeaBlue} solid;
            outline: 0;
            background-color: ${colors.ultraLightGrey};
          }

          p {
            color: ${colors.deepSeaBlue};
            margin: 0;
          }

          svg {
            fill: ${colors.deepSeaBlue};
            width: 24px;
            height: 24px;
            margin-right: 12px;
            flex-shrink: 0;
            transform: rotate(0deg);
            transition: transform 240ms ease;
          }
        }

        .answer {
          padding: 0 1rem 0 calc(36px + 1rem);
          max-height: 0px;
          overflow: hidden;
          transition: padding 240ms ease, max-height 240ms ease;

          & p:last-child {
            margin: 0;
          }
        }

        &:last-child {
          border-bottom: 0px;
        }

        &.expanded {
          .title {
            p {
              color: ${colors.seaBlue};
            }
            svg {
              fill: ${colors.seaBlue};
              transform: rotate(45deg);
              transition: transform 240ms ease;
            }
          }

          .answer {
            padding-top: 1rem;
            padding-bottom: 2rem;
            max-height: 1100px;
            overflow-x: hidden;
            overflow-y: auto;
            transition: padding 240ms ease, max-height 240ms ease;
          }
        }

        & .title:hover {
          cursor: pointer;
          background-color: ${colors.lightGrey};
        }
      }
    }
  `
}

export default FAQPage
