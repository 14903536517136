const questions = [
  {
    question:
      'Alle vores medarbejdere har adgang til mail og intranet. Er det ikke rigeligt? ',
    answer: [
      'Ekko App er ikke en erstatning, men det er et middel til at nå medarbejderne med vigtig information, som er relevant for den enkelte.',
      'Ofte har medarbejderen ikke tid til at kigge på intranettet i løbet af dagen. Med Ekko App får de informationen komprimeret - så har de altid mulighed for at gå ind og læse mere på intranettet.',
      'Mange medarbejdere får så mange mails og lange mails i løbet af en dag, at det er naturligt at sortere lidt i det i løbet af dagen.',
      'Erfaring viser, at Ekko App lige så tit tjekkes på vej til og fra job eller hjemme i sofaen. Fordi den er sjov og motiverende at tjekke.',
    ],
  },
  {
    question: 'Vores medarbejdere har ikke en firmatelefon. Hvad gør vi så? ',
    answer: [
      'Vores erfaring viser, at medarbejderne helst vil have Ekko App på deres private smartphone, hvor de har alle de andre apps, som de løbende tjekker.',
      'Og med det motiverende pointsystem og muligheden for at vidensdele og prale lidt på væggen, bliver Ekko App lige så naturligt for dine medarbejdere at tjekke dagligt som Facebook, Twitter og Instragram!',
    ],
  },
  {
    question:
      'Det ser smart ud, men medarbejderne skal jo arbejde og ikke sidde og swipe hele tiden. Hvad er jeres erfaringer med det?',
    answer: [
      'Vores erfaringer er, at Ekko App bliver brugt på samme måde, som man bruger andre sociale medier. De bliver lige tjekket en gang i mellem (det kender du sikkert fra dig selv). I gennemsnit tjekker en bruger Ekko App 4,7 gange om dagen.',
      'Det interessante er også, at den bliver tjekket ligeså meget udenfor arbejdstiden som i arbejdstiden. På vej til og fra job eller hjemme i sofaen, hvor vi lige tjekker Facebook, Instragram og så Min Ekko Firma App.',
      'Det betyder, at I som virksomhed kommer ud i et helt andet omfang end tidligere til medarbejderne. Og de vælger selv at tilgå den, for det er motiverende for dem. De skal lige se, om der er nye ting i appen, som gør det muligt for dem at optjene point. Eller de skal lige se, hvad kollegaen har svaret på deres indlæg på væggen.',
    ],
  },
  {
    question: 'Giver Ekko App ikke bare en masse merarbejde? ',
    answer: [
      'Ekko App er ikke udviklet til lange romaner. Faktisk er det et værktøj, der gør det muligt at formidle et kort og enkelt budskab. Dine medarbejdere kan altid læse mere om emnet på intranettet eller andetsteds, hvis de har behov for at vide mere.',
      'Ekko App er desuden hurtig at arbejde med. Fx tager det 1 minut at oprette en nyhed, 5 minutter at oprette en quiz og 5 minutter at oprette en survey. ',
    ],
  },
  {
    question:
      'Jeg tror ikke, at det der med point er måden hos os. Vi vil gerne bare sikre os, at medarbejderne har læst og forstået budskabet. Kan man det? ',
    answer: [
      'Ja, det kan man godt. I bestemmer nemlig selv, om medarbejderen optjener point eller ej. Hvis ikke, får de mulighed for at swipe en "læst og forstået"-knap. Så snart de har swipet, kan du se det i Ekko Updater.',
      'Vores erfaring er dog, at pointsystemet virker og motiverer medarbejderne. Pointsystemet appellerer for langt de flestes vedkommende til vores konkurrencegen. Og hvem vil ikke gerne optjene flere point end chefen? Det giver både noget at snakke om, et fællesskab og en mulighed for at blære sig.',
    ],
  },
  {
    question:
      'Vi laver allerede en medarbejderundersøgelse 1 gang årligt, så er det ikke dobbeltarbejde?',
    answer: [
      'Ikke nødvendigvis.',
      'Mange bruger nemlig Ekko App til små hurtige medarbejderundersøgelser. Ved at spørge ofte, har I som virksomhed hele tiden mulighed for at rette ind og forbedre.',
      'Og når medarbejderne får point for at være med, og muligheden for at blive fremhævet som månedens medarbejder, ja, så behøver du slet ikke at rykke for svar, som du måske plejer?',
    ],
  },
  {
    question: 'Hvordan får jeg indhold i Ekko App? ',
    answer: [
      'Direkte i din browser. I Ekko Updater kan du vedligeholde og oprette nyt indhold til Ekko App. Du logger blot på systemet med brugernavn og password.',
      'I Ekko Updater har du desuden mulighed for at trække statistik. Du kan se, hvem der har læst og hvem, der ikke har læst information, point, svar og meget mere.',
    ],
  },
  {
    question: 'Er Ekko App endnu et IT-projekt? ',
    answer: [
      'Slet ikke. Ekko App er en standard-applikation, plug and play, som parameteropsættes til din virksomhed. Den hostes og vedligeholdes af ToEcho, så du skal ikke installere noget på din server. ',
      'Vores best practise implementering gør det muligt at komme i gang i løbet af 1 måned.',
      'De administratorer, som du ønsker skal kunne oprette indhold, opretter dette i det meget simple administrationssystem Ekko Updater direkte i en browser. Her kan du oprette indhold, se statistik, oprette og lukke adgang for brugere og meget mere.',
    ],
  },
  {
    question: 'Det ser dyrt ud. Hvad koster det? ',
    answer: [
      'Du betaler et engangsbeløb for etableringsomkostninger, der dækker analyse, opsætning, instruktion mv.',
      'Derefter betaler du en pris pr. medarbejder pr. måned baseret på hvor mange af modulerne du ønsker med i løsningen.',
      'Ekko App er bygget på en standardløsning, som kan parameteropsættes. Det betyder, at vi nemt og hurtigt kan opsætte appen efter jeres ønsker, og ikke mindst at vi kan holde priserne nede!',
      'Uforpligtende tilbud fås ved henvendelse til på 20 75 75 35.',
    ],
  },
]

export default questions
